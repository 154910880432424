import axios, { AxiosRequestHeaders } from "axios";
import snakeize from "snakeize";
import camelize from "camelize";

function getAuthenticityToken() {
  const token = document.querySelector('meta[name="csrf-token"]');
  if (token && token instanceof window.HTMLMetaElement) {
    return token.content;
  }
  return null;
}

const setupAxios = () => {
  axios.interceptors.request.use(
    (config) => {
      if (!config.headers) config.headers = {} as AxiosRequestHeaders;
      config.headers["X-CSRF-TOKEN"] = getAuthenticityToken() || "";
      config.headers["X-Requested-With"] = "XMLHttpRequest";

      if (
        config.data &&
        Object.prototype.toString.call(config.data) !== "[object FormData]"
      ) {
        config.data = snakeize(config.data);
      }
      if (config.params) {
        config.params = snakeize(config.params);
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (config) => {
      config.data = camelize(config.data);
      return config;
    },
    (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }
      if (error.response) {
        return Promise.reject(
          error.response.data.errors ||
            error.response.data.error || [error.message]
        );
      }

      return Promise.reject([error.message]);
    }
  );
};

export default setupAxios;
