import React, { useState } from "react";
import ReactFlow, { Controls } from 'react-flow-renderer';
import useTree from "./hooks/useTree";
import { RawIdealsType } from "./types";

const Tree = ({ rawIdeals }: RawIdealsType) => {
  const { initialNodes, initialEdges } = useTree({ rawIdeals });

  const [nodes] = useState(initialNodes);
  const [edges] = useState(initialEdges);

  return (
    <div className="p-tree">
      <ReactFlow nodes={nodes} edges={edges} fitView >
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default Tree;
