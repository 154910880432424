$(document).on("turbolinks:load", function () {
  const slideList = document.querySelectorAll(".js-slideOver");
  for (let i = 0; i < slideList.length; i++) {
    const slideRow = slideList[i].querySelectorAll("tr");
    const maxSlideHeight = 460;
    const currentHeight = 0;

    for (let j = 0; j < slideRow.length; j++) {
      currentHeight += slideRow[j].offsetHeight;

      if (currentHeight > maxSlideHeight) {
        const newSlide = slideList[i].cloneNode(true);

        [].forEach.call(
          slideList[i].querySelectorAll("tbody tr"),
          function (x, y) {
            if (y >= j - 1) x.parentNode.removeChild(x);
          }
        );
        [].forEach.call(newSlide.querySelectorAll("tbody tr"), function (x, y) {
          if (y < j - 1) x.parentNode.removeChild(x);
        });

        slideList[i].after(newSlide);
        currentHeight = 0;
      } else {
      }
    }
  }
});
