import { v4 as uuidv4 } from "uuid";
import { RawIdealsType } from "../types";

const useTree = ({ rawIdeals }: RawIdealsType) => {
  let causeDescendantsCount = 0;
  let beforeCauseDescendantsCount = 0;
  let realityDescendantsCount = 0;
  let beforeRealityDescendantsCount = 0;
  let idealDescendantsCount = 0;
  let beforeIdealDescendantsCount = 0;

  const initialNodes = rawIdeals
    .map((ideal) => {
      beforeIdealDescendantsCount += idealDescendantsCount;
      if (ideal.index === 0) beforeIdealDescendantsCount = 0;
      idealDescendantsCount = ideal.descendantsCount;
      return [
        {
          id: `i-${ideal.id}`,
          type: "input",
          sourcePosition: "right",
          data: { label: ideal.content },
          position: { x: 0, y: 0 + beforeIdealDescendantsCount * 100 },
        },
        ideal.rawRealities.map((reality) => {
          beforeRealityDescendantsCount += realityDescendantsCount;
          if (reality.index === 0) beforeRealityDescendantsCount = 0;
          realityDescendantsCount = reality.descendantsCount;
          return [
            {
              id: `r-${reality.id}`,
              sourcePosition: "right",
              targetPosition: "left",
              parentNode: `i-${reality.idealId}`,
              data: { label: reality.content },
              position: { x: 180, y: beforeRealityDescendantsCount * 100 },
            },
            reality.rawCauses.map((cause) => {
              beforeCauseDescendantsCount += causeDescendantsCount;
              if (cause.index === 0) beforeCauseDescendantsCount = 0;
              causeDescendantsCount = cause.descendantsCount;
              return [
                {
                  id: `c-${cause.id}`,
                  sourcePosition: "right",
                  targetPosition: "left",
                  parentNode: `r-${cause.realityId}`,
                  data: { label: cause.content },
                  position: { x: 180, y: beforeCauseDescendantsCount * 100 },
                },
                cause.rawDeepCauses.map((deepCause) => {
                  return [
                    {
                      id: `d-${deepCause.id}`,
                      type: "output",
                      targetPosition: "left",
                      parentNode: `c-${deepCause.causeId}`,
                      data: { label: deepCause.content },
                      position: { x: 180, y: deepCause.index * 100 },
                    },
                  ];
                }),
              ];
            }),
          ];
        }),
      ];
    })
    .flat(Infinity);

  const initialEdges = rawIdeals
    .map((ideal) => {
      return ideal.rawRealities.map((reality) => {
        return [
          {
            id: uuidv4(),
            source: `i-${reality.idealId}`,
            target: `r-${reality.id}`,
          },
          reality.rawCauses.map((cause) => {
            return [
              {
                id: uuidv4(),
                source: `r-${cause.realityId}`,
                target: `c-${cause.id}`,
              },
              cause.rawDeepCauses.map((deepCause) => {
                return [
                  {
                    id: uuidv4(),
                    source: `c-${deepCause.causeId}`,
                    target: `d-${deepCause.id}`,
                  },
                ];
              }),
            ];
          }),
        ];
      });
    })
    .flat(Infinity);

  return {
    initialNodes,
    initialEdges,
  };
};

export default useTree;
