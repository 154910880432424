
// HTTP POSTリクエストを送信する関数を定義
async function postToEndpoint(endpoint, data) {
  try {
    const url = `/api/teams/${gon.team_id}/tree/${endpoint}`; // 移動先によって
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
      'API_KEY': process.env.HTTP_API_KEY
    };
    console.log(data);
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers(headers),
    });

     // レスポンスのJSONを解析します。
    const response_data = await response.json();

    if (response.ok) {
      // レスポンスがOKの場合（HTTPステータスが2xxの場合）
      if (response_data.team_id) {
        // リダイレクトURLがあれば、そこに遷移。
        const redirectUrl = `/teams/${response_data.team_id}/tree`;
        window.location.href = redirectUrl;
      } else {
        // リダイレクトURLがない場合、成功した処理をここに記述。
        console.log('Success:', data);
      }
    }
    else {
      const errorContainer = document.getElementById('error-container');
      const errorElement = document.getElementById('error-messages');
      let errorsHtml = '';
      errorContainer.style.display = "flex";

      const errorMessageScrollPosition = errorContainer.offsetTop - 32;
      window.scrollTo({ top: errorMessageScrollPosition, behavior: "instant"});
      
      errorsHtml = `<p class="error-text">${response_data.error}</p>`; // response_data.errorはサーバーからのレスポンスに依存。

      // 複数のエラーを表示する必要が出た場合にこの条件分けを復活させる
      // if (Array.isArray(response_data.errors)) {
      //   response_data.errors.forEach(error => {
      //     errorsHtml += `<p>${error}</p>`;
      //   });
      // } else {
      //   単一のエラーメッセージの場合、そのまま表示。
      //   errorsHtml = `<p class="error-text">${response_data.error}</p>`; // response_data.errorはサーバーからのレスポンスに依存。
      // }

      errorElement.innerHTML = errorsHtml;
      throw new Error(`HTTPエラー ${response.status}`);
    }


  } catch (error) {
    console.error('HTTPリクエストエラー:', error);
  }
}



let draggedItem = null;
let currentDropArea = null;

// ドラッグ開始時の処理
document.addEventListener('dragstart', function (e) {
//   // クリックした要素がtask-contetクラスを持つか確認
  if (e.target.classList.contains('c-treecard')) {
    draggedItem = e.target;
    draggedItem.classList.add('c-treecard-dragging');
  }
});

// ドラッグ領域に要素をドロップした際の処理
document.addEventListener('dragover', function (e) {
  e.preventDefault();
  if (e.target.classList.contains('c-drop-area')) {
    // ドロップ先がtask-tree-areaなら、その要素の背景色を水色に変更
    e.target.style.backgroundColor = '#E9FAFF';
    currentDropArea = e.target;
  } else {
    // ドロップ対象外の場合、背景色をクリア
    if (currentDropArea) {
      currentDropArea.style.backgroundColor = '';
      currentDropArea = null;
    }
  }
});

// ドラッグ終了時の処理 ここでpostかな
document.addEventListener('dragend', function (event) {
  if (draggedItem) {
    draggedItem.classList.remove('c-treecard-dragging');
    draggedItem = null;
  }

  const draggedId = event.target.dataset.cardId
  const currentCategory = event.target.dataset.categoryType
  const hoverElement = document.elementFromPoint(event.clientX, event.clientY);
  if(!hoverElement) return
  const hoverId = hoverElement.dataset.cardId
  if(!hoverId) return

  const category = hoverElement.dataset.categoryType
  if(!category) return

  const data = {}
  switch(currentCategory) { //移動元
    case "ideals":
      data.ideal_id = draggedId
      break
    case "realities":
      data.reality_id = draggedId
      break
    case "causes":
      data.cause_id = draggedId
      break
    case "deep_causes":
      data.deep_cause_id = draggedId
      break
  }
  switch(category) { //移動先
    case "ideals":
      data.ideal_id = hoverId
      break
    case "realities":
      data.reality_id = hoverId
      break
    case "causes":
      data.cause_id = hoverId
      break
    case "deep_causes":
      data.deep_cause_id = hoverId
      break
  }

  postToEndpoint(category, data)
});

// ドロップ対象から出た際の処理
document.addEventListener('dragleave', function (e) {
  if (currentDropArea) {
    currentDropArea.style.backgroundColor = '';
    currentDropArea = null;
  }
});

// ドロップした際の処理
document.addEventListener('drop', function (e) {
  e.preventDefault();
  if (currentDropArea) {
    // 背景色を元に戻す
    currentDropArea.style.backgroundColor = '';
    currentDropArea = null;

    const  dataId = e.dataTransfer.getData('text/plain');

  }
});

// エラーメッセージの削除
document.addEventListener('DOMContentLoaded', function () {
  const errorCloseBtn = document.getElementById('error-close-btn');
  const errorContainer = document.getElementById('error-container');
  errorCloseBtn.addEventListener('click', function () {
    errorContainer.style.display = "none";
  });

});

